body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  box-shadow: inset 0 0 0 0 currentColor;
  transition: box-shadow 0.2s ease-in-out;
}

a:hover {
  box-shadow: inset 0 -1px 0 0 currentColor;
}

.small {
  font-size: 0.6875rem;
}

.text-center {
  text-align: center;
}

.text-underlined {
  text-decoration: underline;
  text-underline-offset: 0.15em;
}

.container {
  padding-top: 1.25rem;
  max-width: 750px;
}

.main-logo {
  max-width: 5rem;
  max-height: 2.0213rem;
  height: 100%;
}

.footer {
  display: flex;
  gap: 1.25rem;
  padding-top: 1.4375rem;
  padding-bottom: 1.6875rem;
}

.footer a {
  color: #505050;
  letter-spacing: 0;
  font-size: 0.875rem;
  line-height: 1.125rem;
}
